import $ from 'jquery'

const Layer = () => {
    // create instance
    const layer = {
        init() {
            // define essential elements
            layer.$element = $('.layer')

            if (layer.$element.length) {
                // define elements
                layer.$conceal = $('.trigger--layer-conceal')

                // set open state
                if (sessionStorage.getItem('layerclosed') !== 'yes') {
                    layer.reveal()
                }

                // bind events
                layer.$conceal.bind('click', () => {
                    layer.conceal()
                })
            }
        },
        reveal() {
            // add body classes
            $('body').addClass('layer--is-active')
            $('body').addClass('layer--is-visible')
        },
        conceal() {
            // store state
            window.sessionStorage.setItem('layerclosed', 'yes')

            // add body classes
            $('body').removeClass('layer--is-visible')
            setTimeout(() => {
                $('body').removeClass('layer--is-active')
            }, 500)
        },
    }

    // initialize instance
    layer.init()
}

export default Layer
