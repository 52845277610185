import "core-js/stable";
import "regenerator-runtime/runtime";

import { Fx } from '../src/01_atoms/Fx/scripts/Fx';
import { Navigato } from '../src/01_atoms/Navigato/Navigato';
import { Offcanvas } from '../src/01_atoms/Offcanvas/Offcanvas';
import CookieConsent from '../src/02_molecules/Cookie/Cookie';
import Share from 'share-buttons';
import { MasonryGrid } from './03_organisms/MasonryGrid/MasonryGrid';
import { Pagination } from './02_molecules/Pagination/Pagination';
import React from 'react';
import ReactDom from "react-dom";
import ATMLocator from './03_organisms/ATMLocator/ATMLocator';
import { Form } from "./02_molecules/Form/Form";
import { Progressbar } from './03_organisms/Progressbar/Progressbar';
import Layer from './03_organisms/Layer/Layer';

// Initialize application
const App = () => {
	Offcanvas();
	Navigato();
	Fx();
	MasonryGrid();
	Pagination();
	Form();
	Progressbar();
	Layer();

	// ATM Locator
	const ReactDomExists = !!document.getElementById("ATMLocator");
	ReactDomExists && ReactDom.render( <ATMLocator /> ,document.getElementById("ATMLocator"));

	new CookieConsent();
};

// Add event listener
// window.addEventListener('load', App);
document.addEventListener("DOMContentLoaded", function(event) {
    App();
});
