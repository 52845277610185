import Masonry from "masonry-layout";

export const MasonryGrid = () => {

    //const _grid = document.getElementById('masonry-grid') || false;
    let _grids = document.querySelectorAll('.masonry-grid') || false;
    if(!_grids) return false;

    for (let i = 0; i < _grids.length; i++) {
        let _grid = _grids[i];

        let _masonry_grid = new Masonry( _grid, {
            isInitLayout: false,
            itemSelector: '.js-masonry-item',
            columnWidth: '.js-masonry-sizer',
            gutter: '.js-masonry-gutter-sizer',
            percentPosition: true,
            horizontalOrder: true,
            stagger: 0
        });

        _masonry_grid.once( 'layoutComplete', function() {
            _grid.style.opacity = 1;
        });

        _masonry_grid.layout();
    }

};
