import "react-app-polyfill/ie11";
import { useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import _debounce from "lodash/debounce";
import _filter from "lodash/filter";
import React, { useEffect, useRef, useState } from "react";
import ATMDetail from "../../02_molecules/ATMDetail/ATMDetail";
import ATMFilter from "../../02_molecules/ATMFilter/ATMFilter";
import ATMMap from "../../02_molecules/ATMMap/ATMMap";
import ATMPane from "../../02_molecules/ATMPane/ATMPane";
import ATMResults from "../../02_molecules/ATMResults/ATMResults";
import ATMSearch from "../../02_molecules/ATMSearch/ATMSearch";
import { devide_by_a_million } from "../../utils/dbam";

const libs = ["places,geometry"];
let navigateMap = null;

function ATMLocator(props) {
  const mapRef = useRef(null);

  const language = document
    .getElementById("ATMLocator")
    .getAttribute("data-lang");
  const [doSearchReset, setDoSearchReset] = useState(undefined);
  const [allLocations, setAllLocations] = useState(undefined);

  const [locations, setLocations] = useState(undefined);
  useEffect(() => {
    axios
      .get(`/${language || "nl"}/locations`)
      .then(function (response) {
        response.data.data.map((location) => {
          location.lat_times_a_million = devide_by_a_million(
            location.lat_times_a_million
          );
          location.lng_times_a_million = devide_by_a_million(
            location.lng_times_a_million
          );
        });

        setAllLocations(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [activeFilter, setActiveFilter] = useState("all");
  const [filteredLocations, setFilteredLocations] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentSearchLocation, setCurrentSearchLocation] = useState(null);

  let defaultZoom = 8;
  let defaultCenter = {
    lat: 50.85045,
    lng: 4.34878,
  };

  const { isLoaded = false, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAUXqC-DKNYokAdJklKK6FgE_Pc6mLz2LY",
    libraries: libs,
    language: language,
  });

  const onMapLoaded = (navigateMapFunction) => {
    navigateMap = navigateMapFunction;
  };

  const handleLocationClick = (location) => {
    setCurrentLocation({ ...location });
    window.innerWidth < 767 &&
      mapRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onSearchLocationChange = (location) => {
    setCurrentLocation(null);
    setCurrentSearchLocation(location);
  };

  const handleBackToResultsClick = () => {
    setCurrentLocation(null);
    setDoSearchReset(Math.random() * 10);
  };

  const onMapBoundsChanged = _debounce((map) => {
    const _bounds = map.getBounds();
    const _center = map.getCenter();

    let _visibleLocations = [];

    locations.map((location) => {
      if (
        _bounds?.contains({
          lat: Number(location.lat_times_a_million),
          lng: Number(location.lng_times_a_million),
        })
      ) {
        _visibleLocations.push(location);
      }
    });

    _visibleLocations.sort(function (a, b) {
      return (
        window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng({
            lat: Number(a.lat_times_a_million),
            lng: Number(a.lng_times_a_million),
          }),
          _center
        ) -
        window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng({
            lat: Number(b.lat_times_a_million),
            lng: Number(b.lng_times_a_million),
          }),
          _center
        )
      );
    });

    setFilteredLocations(_visibleLocations);
  }, 200);

  useEffect(() => {
    if (currentSearchLocation) {
      navigateMap({
        center: currentSearchLocation.toJSON(),
        zoom: 11,
      });
    } else {
      setCurrentLocation(null);

      if (navigateMap) {
        navigateMap({
          center: defaultCenter,
          zoom: defaultZoom,
        });
      }
    }
  }, [currentSearchLocation]);

  useEffect(() => {
    if (activeFilter === "all") {
      setLocations(allLocations);
    } else {
      setLocations(_filter(allLocations, ["status", activeFilter]));
    }

    if (navigateMap) {
      navigateMap({});
    }
  }, [allLocations, activeFilter]);

  return isLoaded && locations ? (
    <>
      <div className="locator-control">
        <ATMSearch
          reset={doSearchReset}
          onSearchLocationChange={onSearchLocationChange}
        />
        <ATMFilter
          onActiveFilter={(filter) => {
            setActiveFilter(filter);
          }}
        />
      </div>

      <div ref={mapRef} className="locator-main">
        <ATMMap
          onLoad={onMapLoaded}
          markers={locations}
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
          currentMarker={currentLocation}
          onMarkerClick={handleLocationClick}
          onBoundsChanged={onMapBoundsChanged}
        />

        <ATMPane>
          {/* { currentLocation ?
                            <ATMDetail location={currentLocation} handleBackToResultsClick={handleBackToResultsClick}/> :
                            <ATMResults locations={filteredLocations} handleLocationClick={handleLocationClick}/>
                        } */}
          <ATMResults
            locations={filteredLocations}
            handleLocationClick={handleLocationClick}
          />
        </ATMPane>
      </div>
    </>
  ) : (
    <div className="locator-main locator-main--loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
        viewBox="0 0 168 168"
      >
        <path d="M138 84c0 13.8-5.3 27.7-15.8 38.2-21.1 21.1-55.3 21.1-76.4 0-21.1-21.1-21.1-55.3 0-76.4C56.4 35.3 70.2 30 84.1 30V0C62.6 0 41 8.2 24.6 24.6c-32.8 32.8-32.8 86 0 118.8s86 32.8 118.8 0C159.8 127 168 105.5 168 84h-30z" />
      </svg>
    </div>
  );
}

export default ATMLocator;
