import React, { useEffect, useState } from "react";
import AtmLocatorTranslations from "../../utils/AtmLocatorTranslations";
import style from "./styles/filter.module.scss";

function ATMFilter({ onActiveFilter, ...props }) {
  const language = document
    .getElementById("ATMLocator")
    .getAttribute("data-lang");

  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  useEffect(() => {
    onActiveFilter(filter);
  }, [filter]);

  return (
    <div className={style.filterWrapper}>
      {isOpen ? (
        <div className={style.filterList}>
          <div
            onClick={() => {
              setFilter("all");
              setIsOpen(false);
            }}
            className={style.filterItem}
          >
            {AtmLocatorTranslations[language].all}
          </div>

          <div
            onClick={() => {
              setFilter("active");
              setIsOpen(false);
            }}
            className={style.filterItem}
          >
            {AtmLocatorTranslations[language].active}
          </div>

          <div
            onClick={() => {
              setFilter("planned");
              setIsOpen(false);
            }}
            className={style.filterItem}
          >
            {AtmLocatorTranslations[language].planned}
          </div>
          <div
            onClick={() => {
              setFilter("wanted");
              setIsOpen(false);
            }}
            className={style.filterItem}
          >
            {AtmLocatorTranslations[language].wanted}
          </div>
        </div>
      ) : (
        <div onClick={() => setIsOpen(true)} className={style.activeFilter}>
          <div className={style.activeFilterInner}>
            <span>{AtmLocatorTranslations[language][filter]}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14.4}
              height={8.9}
              viewBox="0 0 14.4 8.9"
              overflow="visible"
            >
              <path
                d="M0 1.7L1.7 0l5.5 5.5L12.7 0l1.7 1.7-7.2 7.2L0 1.7z"
                fill="#282727"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
}

export default ATMFilter;
