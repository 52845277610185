import scrollMonitor from 'scrollmonitor';

export const Fx = () => {

	// create instance
	const fx = {
		init: function() {

			// define elements
			fx.$reveal = document.querySelectorAll('.fx--reveal-trigger');
			fx.$parallax = document.querySelectorAll('.fx--parallax-trigger');

			// added loaded class
			document.body.classList.add("fx--is-loaded");

			// define variables
			fx.scrollPos = 0;

			// loop through elements
			if(fx.$reveal) {

				for(var i=0; i<fx.$reveal.length; i++) {

					(function(count) {

						// define variables
						var thisELEMENT = fx.$reveal[count];
						var thisWATCHER = scrollMonitor.create(thisELEMENT);

						// bind events
						thisWATCHER.enterViewport(function() {

							thisELEMENT.classList.add('is-visible');
							// thisWATCHER.destroy();

						});

					})(i);

				}

			}

			// bind events
			if(fx.$parallax) {

				//setTimeout(function() {

					fx.performScroll();

				//}, 1500);

				window.addEventListener('scroll', function(e) { fx.performScroll(); });

			}

		},
		performScroll: function() {

			// define variables
			fx.scrollPos = window.pageYOffset || document.documentElement.scrollTop;

			// loop through elements
			for(var i=0; i<fx.$reveal.length; i++) {

				(function(count) {

					// define variables
					var thisELEMENT = fx.$parallax[count];

					if(thisELEMENT){
						var viewportH = window.innerHeight;
						var thisTOP = thisELEMENT.getBoundingClientRect().top || 0;

						if(thisTOP <= viewportH && thisTOP >= 0) {

							// define extra variables
							var thisRATIO = (thisTOP / viewportH);
							var thisOFFSET = thisRATIO * (viewportH / 8);

							// set new styles
							thisELEMENT.style.transform = 'translate3d(0, '+ thisOFFSET +'px, 0)';

						}
					}
					

				})(i);

			}

		}
	};

	// initialize instance
	fx.init();

};
