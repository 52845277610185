import React from "react";
import style from './styles/pane.module.scss';


const ATMPane = ({...props}) => {

    return (
       
        <div className={style.pane}>
            {props.children}
        </div>
        
    )
}

export default ATMPane;
