const AtmLocatorTranslations = {
  nl: {
    back: "Terug",
    reset: "Wissen",
    all: "Alle locaties",
    active: "Actieve locaties",
    planned: "Locaties in opbouw",
    wanted: "Geschikte locatie wordt gezocht",
    search: "Zoek een locatie vlakbij",
    no_results: "Geen resultaat gevonden voor deze locatie.",
  },
  fr: {
    back: "Retour",
    reset: "Effacez",
    all: "Tous les emplacements",
    active: "Emplacements actifs",
    planned: "Emplacements en construction",
    wanted: "Lieux encore à déterminer",
    search: "Cherchez un emplacement",
    no_results: "Il n’y a pas de résultat pour cet emplacement.",
  },
  en: {
    back: "Back",
    reset: "Clear",
    all: "All locations",
    active: "Active locations",
    planned: "Planned locations",
    wanted: "Locations pending",
    search: "Find a location near you",
    no_results: "There is no result for this location.",
  },
  de: {
    back: "Zurück",
    reset: "Klar",
    all: "Alle Standorte",
    active: "Aktive Standorte",
    planned: "Im Aufbau befindliche Standorte",
    wanted: "Noch zu suchende Standorte",
    search: "Einen Standort in der Nähe suchen",
    no_results: "Für diesen Ort gibt es kein Ergebnis.",
  },
};

export default AtmLocatorTranslations;
