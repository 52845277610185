import React, {useEffect, useState} from "react";
import style from './styles/searchbar.module.scss';
import {Autocomplete} from '@react-google-maps/api';
import classNames from "classnames";
import AtmLocatorTranslations from "../../utils/AtmLocatorTranslations";

const ATMSearch = ({...props}) => {

    const language = document.getElementById('ATMLocator').getAttribute('data-lang');

    const [autocomplete, setAutocompleteRef] = useState([]);

    const [hasResult, setHasResult] = useState(false);

    const countryBounds = new window.google.maps.LatLngBounds();
    countryBounds.extend({lat: 49.5294835476, lng: 2.51357303225});
    countryBounds.extend({lat: 51.4750237087, lng: 6.15665815596});

    useEffect(()=>{
        handleResetClick()
    },[props.reset])

    function onAutocompleteLoaded(ref) {
        setAutocompleteRef(ref);
    }

    function onPlaceChanged() {
        let result = autocomplete.getPlace();

        if (result) {

            props.onSearchLocationChange(result.geometry.location);
            setHasResult(true);

        } else {
            // Reset Box
        }
    }

    function handleResetClick(e) {
        e && e.preventDefault();
        props.onSearchLocationChange(null);
        resetSearchbox();
    }

    function resetSearchbox() {
        document.getElementById("searchbox").value = "";
        setHasResult(false);
    }


    return (

        <div className={classNames(style.searchbar, hasResult && style.hasResult)}>
            <Autocomplete
                className={style.search}
                onLoad={onAutocompleteLoaded}
                bounds={countryBounds}
                onPlaceChanged={onPlaceChanged}
                options={{types: ['geocode'],strictBounds:countryBounds,componentRestrictions:{country:['be','lu']}}}
            >
                <input
                    id="searchbox"
                    type="text"
                    placeholder={AtmLocatorTranslations[language].search}
                />
            </Autocomplete>


            {
                hasResult ? 
                    <button className={style.backButton} onClick={handleResetClick} disabled={!hasResult}>{AtmLocatorTranslations[language].reset}</button> 
                :   null
            }


        </div>

    )
}

export default ATMSearch;