export const Offcanvas = () => {

    // define elements
    const triggers = document.getElementsByClassName('trigger--offcanvas-toggle');

    // bind events
    for(var i=0; i<triggers.length; i++) {

        triggers[i].addEventListener('click', function(e) { e.preventDefault(); document.body.classList.toggle('offcanvas--is-open'); });

    }

};