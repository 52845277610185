import findAncestor from '../../utils/findAncestor';

export const Form = () => {

    // define elements
    const triggers = document.getElementsByTagName('input');
    
    // bind events
    let validate = (e) => { 

        e.currentTarget.classList.remove('error');

        if(e.currentTarget.getAttribute('type') === 'radio'){
           
            let _parentWithErrorClass = findAncestor(e.currentTarget,'fieldset-wrapper');
            let _SiblingsWithError = _parentWithErrorClass.getElementsByTagName('input');
        
            for(var i=0; i<_SiblingsWithError.length; i++) {
            _SiblingsWithError[i].classList.remove('error')
            }
        }

    }


    for(var i=0; i<triggers.length; i++) {

        triggers[i].addEventListener('change', validate, false );

    }

};