import * as Cookie from "tiny-cookie";
import _difference from "lodash/difference";

let SYS = {
    cookieConsentKey: "cookie-consent-level",
};

export default class CookieConsent {
    constructor() {
        if (
            document.querySelector(".js-cookie-modal") &&
            document.querySelector(".js-cookie-msg")
        ) {
            this.triggerModalElements = Array.from(
                document.querySelectorAll(".js-show-cookie-policy")
            );

            this.modal = document.querySelector(".js-cookie-modal");
            this.message = document.querySelector(".js-cookie-msg");
            this.scroller = document.querySelector(".js-cookie-msg--scroller");
            this.consentLevels = ["1"];
            this.consentCheckBoxes = document.querySelectorAll(
                '.js-cookie-modal .c-switch input[type="checkbox"]'
            );

            this.btnAccept = document.querySelector(".js-accept-cookies");
            this.btnDecline = document.querySelector(".js-decline-cookies");
            this.btnDeclineInstant = document.querySelector(
                ".js-instant-decline-cookies"
            );
            this.btnSave = document.querySelector(".js-save-cookies");
            this.btnContinue = document.querySelector(".js-continue-cookies");

            this.onAcceptClick = (e) => {
                this.doSetCookie(["1", "3"]);
            };

            this.onDeclineClick = (e) => {
                this.doSetCookie(["1"]);
            };

            this.onSaveClick = (e) => {
                e.preventDefault();
                let tempconsent = [];
                for (var i = 0; i < this.consentCheckBoxes.length; i++) {
                    const cb = this.consentCheckBoxes[i];
                    const value = cb.getAttribute("value");

                    if (cb.checked && tempconsent.indexOf(value) === -1) {
                        tempconsent.push(value);
                    } else if (!cb.checked) {
                        tempconsent = tempconsent.filter(
                            (item) => item !== value
                        );
                    }
                }

                this.doSetCookie(tempconsent);
            };

            this.init();
        }
    }

    init() {
        if (Cookie.isEnabled()) {
            if (Cookie.getCookie(SYS.cookieConsentKey) === null) {
                // show message
                this.showMessage();

                this.btnContinue.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.doSetCookie(["1", "3", "4"]);
                    this.hideMessage();
                });

                this.btnDeclineInstant.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.doSetCookie(["1"]);
                    this.hideMessage();
                });
            } else {
                this.consentLevels = Array.from(
                    Cookie.getCookie(SYS.cookieConsentKey)
                );
            }
        }

        this.triggerModalElements.forEach((el, i) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                this.hideMessage();
                this.showModal();
            });
        });
    }

    showMessage() {
        this.message.classList.add("is-visible");
        this.scroller.classList.add("is-visible");
    }

    hideMessage() {
        this.message.classList.remove("is-visible");
        this.scroller.classList.remove("is-visible");
    }

    showModal() {
        this.modal.classList.add("is-visible");
        this.updateConsentUI();
        this.bindModalEvents();
    }

    hideModal() {
        this.modal.classList.remove("is-visible");
        this.unBindModalEvents();
    }

    bindModalEvents() {
        this.btnAccept.addEventListener("click", this.onAcceptClick, false);
        this.btnDecline.addEventListener("click", this.onDeclineClick, false);
        this.btnSave.addEventListener("click", this.onSaveClick, false);
    }

    unBindModalEvents() {
        this.btnAccept.removeEventListener("click", this.onAcceptClick);
        this.btnDecline.removeEventListener("click", this.onDeclineClick);
        this.btnSave.removeEventListener("click", this.onSaveClick);
    }

    updateConsentUI() {
        if (Cookie.getCookie(SYS.cookieConsentKey) != null)
            this.consentLevels = Array.from(
                Cookie.getCookie(SYS.cookieConsentKey)
            );

        for (var i = 0; i < this.consentCheckBoxes.length; i++) {
            const cb = this.consentCheckBoxes[i];
            if (this.consentLevels.includes(cb.getAttribute("value"))) {
                cb.setAttribute("checked", true);
                cb.checked = true;
            } else {
                cb.removeAttribute("checked");
                cb.checked = false;
            }
        }
    }

    doSetCookie(level = ["1"]) {
        //let same = level.filter(element => this.consentLevels.includes(element))
        let diff = _difference(level, this.consentLevels);
        let parcedCookie = level.join("");

        if (Cookie.isEnabled()) {
            if (Cookie.getCookie(SYS.cookieConsentKey) === null) {
                diff = level;
            }

            Cookie.setCookie(SYS.cookieConsentKey, parcedCookie, {
                expires: "1Y",
            });

            if (diff.length > 0) {
                window["dataLayer"].push({
                    event: "cookie-consent-changed",
                    newLevels: diff,
                });
            }
        }

        this.hideModal();
    }
}

export let areCookiesEnabled = function () {
    try {
        document.cookie = "cookietest=1";
        var cookiesEnabled = document.cookie.indexOf("cookietest=") !== -1;
        document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
        return cookiesEnabled;
    } catch (e) {
        return false;
    }
};

export let getCookie = function (cname) {
    if (!areCookiesEnabled()) return "";
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
};
