import classNames from "classnames";
import React from "react";
import AtmLocatorTranslations from "../../utils/AtmLocatorTranslations";
import style from "./styles/results.module.scss";

const ATMResults = ({ locations, ...props }) => {
  const language = document
    .getElementById("ATMLocator")
    .getAttribute("data-lang");

  function handleLocationClick(location) {
    props.handleLocationClick(location);
  }

  return locations && Object.keys(locations).length ? (
    <ul className={style.results}>
      {locations.map((location, i) => (
        <li
          key={i}
          onClick={() => handleLocationClick(location)}
          className={style.resultsitem}
        >
          <div className={style.resultsitemInner}>
            <div>
              {location.status === "active" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="visible"
                  viewBox="0 0 34.9 42.2"
                >
                  {" "}
                  <path
                    d="M.1 15.9c-.4 5 1.3 10.1 5 13.9l12.3 12.4 12.3-12.4c6.8-6.8 6.8-17.9 0-24.7-6.8-6.8-17.9-6.8-24.7 0C3.6 6.5 2.5 8 1.7 9.7.2 13.6.1 15.9.1 15.9z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#fff"
                  />{" "}
                  <path
                    d="M4.2 11.3l4.6 4.6c-.1-2 .8-3.8 2.3-5.4 3.5-3.5 9.4-3.2 13.1.6 3.8 3.8 4.1 9.7.1 13.6-4 4-9.8 3.7-13.6-.1l-8-8c-.4 4.2 1.1 8.6 4.3 11.8L17.5 39 28 28.4c5.8-5.8 5.8-15.2 0-21-5.8-5.8-15.2-5.8-21 0-1.2 1.2-2.1 2.5-2.8 3.9z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#56c57b"
                  />{" "}
                </svg>
              ) : location.status === "wanted" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="visible"
                  viewBox="0 0 34.9 42.2"
                >
                  {" "}
                  <path
                    d="M.1 15.9c-.4 5 1.3 10.1 5 13.9l12.3 12.4 12.3-12.4c6.8-6.8 6.8-17.9 0-24.7-6.8-6.8-17.9-6.8-24.7 0C3.6 6.5 2.5 8 1.7 9.7.2 13.6.1 15.9.1 15.9z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#fff"
                  />{" "}
                  <path
                    d="M4.4 11.216l4.6 4.6c-.1-2 .8-3.8 2.3-5.3 3.4-3.4 9.3-3.2 13 .6 3.8 3.8 4 9.6.1 13.5-3.9 3.9-9.7 3.7-13.5-.1l-7.9-8.1c-.4 4.2 1.1 8.5 4.2 11.7l10.4 10.4 10.4-10.4c5.7-5.7 5.7-15.1 0-20.8-5.7-5.7-15.1-5.7-20.8 0-1.1 1.2-2.1 2.5-2.8 3.9z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#424242"
                  />{" "}
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  overflow="visible"
                  viewBox="0 0 34.9 42.2"
                >
                  {" "}
                  <path
                    d="M.1 15.9c-.4 5 1.3 10.1 5 13.9l12.3 12.4 12.3-12.4c6.8-6.8 6.8-17.9 0-24.7-6.8-6.8-17.9-6.8-24.7 0C3.6 6.5 2.5 8 1.7 9.7.2 13.6.1 15.9.1 15.9z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#fff"
                  />{" "}
                  <path
                    d="M4.4 11.216l4.6 4.6c-.1-2 .8-3.8 2.3-5.3 3.4-3.4 9.3-3.2 13 .6 3.8 3.8 4 9.6.1 13.5-3.9 3.9-9.7 3.7-13.5-.1l-7.9-8.1c-.4 4.2 1.1 8.5 4.2 11.7l10.4 10.4 10.4-10.4c5.7-5.7 5.7-15.1 0-20.8-5.7-5.7-15.1-5.7-20.8 0-1.1 1.2-2.1 2.5-2.8 3.9z"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#696dc6"
                  />{" "}
                </svg>
              )}
            </div>
            <div>
              {location[`name_${language}`] && (
                <p className={style.resultTxtName}>
                  {location[`name_${language}`]}
                </p>
              )}
              <p className={style.resultTxt}>
                {location[`street_${language}`]} {location[`nr`]}
              </p>
              <p className={style.resultTxt}>
                {location.zipcode} {location[`city_${language}`]}
              </p>
              {location[`status_${language}`] && (
                <>
                  <div className={style.divider}></div>
                  <p className={classNames(style.resultTxt, style.italic)}>
                    {location[`status_${language}`]}
                  </p>
                </>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <div className={classNames(style.resultsitem, style.noResults)}>
      <p className={style.resultTxt}>
        {AtmLocatorTranslations[language].no_results}
      </p>
    </div>
  );
};

export default ATMResults;
