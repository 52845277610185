import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerClusterer, Marker } from "@react-google-maps/api";
import style from "./styles/map.module.scss";
import markerIconActive from "./svg/marker_active.svg";
import markerIconPlanned from "./svg/marker_planned.svg";
import markerIconWanted from "./svg/marker_wanted.svg";
import { googleMapsStyler } from "./style.js";

const ATMMap = ({ ...props }) => {
  const [map, setMapInstance] = useState(undefined);
  const onLoad = (ref) => {
    props.onLoad ? props.onLoad(navigateMap) : false;
    setMapInstance(ref);
  };

  const mapStyling = {
    mapContainerStyle: {
      height: "800px",
      width: "100%",
    },
    markerClustererOptions: {
      clusterClass: style.cluster,
      imageSizes: [50, 40, 30],
    },
  };

  const [mapOptions, setMapOptions] = useState({
    styles: googleMapsStyler,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoom: props.defaultZoom ? props.defaultZoom : 8,
    center: props.defaultCenter
      ? props.defaultCenter
      : {
          lat: 50.85045,
          lng: 4.34878,
        },
  });

  useEffect(() => {
    if (props.currentMarker) {
      setMapOptions({
        center: {
          lat: Number(props.currentMarker.lat_times_a_million),
          lng: Number(props.currentMarker.lng_times_a_million),
        },
        zoom: 17,
      });
    } else {
      setMapOptions({
        zoom: props.defaultZoom ? props.defaultZoom : 8,
        center: props.defaultCenter
          ? props.defaultCenter
          : {
              lat: 50.85045,
              lng: 4.34878,
            },
      });
    }
  }, [props.currentMarker]);

  useEffect(() => {
    if (props.markers?.length && map) {
      if (map.fitBounds) {
        map.fitBounds(_getBounds(props.markers), 0);
        setMapOptions({
          center: _getBounds(props.markers).getCenter(),
          //zoom: props.defaultZoom ? props.defaultZoom : 8
        });
      }
    }
  }, [map, props.markers]);

  function navigateMap(params) {
    setMapOptions({
      zoom: params.zoom ? params.zoom : 8,
      center: params.center
        ? params.center
        : {
            lat: 50.85045,
            lng: 4.34878,
          },
    });
  }

  function _getBounds(markers) {
    let filterResultsBounds = new window.google.maps.LatLngBounds();
    markers.map((location) => {
      let locationLatLng = {
        lat: Number(location.lat_times_a_million),
        lng: Number(location.lng_times_a_million),
      };
      filterResultsBounds.extend(locationLatLng);
    });
    return filterResultsBounds;
  }

  function onBoundsChanged() {
    if (props.onBoundsChanged) {
      props.onBoundsChanged(map);
    }
  }

  return (
    <div className={style.map}>
      <GoogleMap
        id="map"
        options={mapOptions}
        mapContainerStyle={mapStyling.mapContainerStyle}
        onLoad={onLoad}
        onBoundsChanged={onBoundsChanged}
      >
        <MarkerClusterer options={mapStyling.markerClustererOptions}>
          {(clusterer) =>
            props.markers.map((location, i) => {
              return (
                <Marker
                  key={i}
                  icon={
                    location.status === "planned"
                      ? markerIconPlanned
                      : location.status === "wanted"
                      ? markerIconWanted
                      : markerIconActive
                  }
                  position={{
                    lat: Number(location.lat_times_a_million),
                    lng: Number(location.lng_times_a_million),
                  }}
                  clusterer={clusterer}
                  optimized={false}
                  onClick={(e) => props.onMarkerClick(location)}
                />
              );
            })
          }
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

export default ATMMap;
