export const Progressbar = () => {

  // check we have a progress bar on this page
  const wrapper = document.querySelector('.progress-wrapper') || false;
  if(!wrapper) return false;


  // progress bars
  const progress_active  = wrapper.querySelector('.progress-bar-active');
  const progress_planned = wrapper.querySelector('.progress-bar-planned');

  /*
  // more info
  const progress_active_details_left  = wrapper.querySelector('.progress-bar-active-details--left');
  const progress_active_details_right = wrapper.querySelector('.progress-bar-active-details--right');
  const progress_planned_details_left  = wrapper.querySelector('.progress-bar-planned-details--left');
  const progress_planned_details_right = wrapper.querySelector('.progress-bar-planned-details--right');
  */

  // get some widths
  const width_active = Math.round(parseInt(progress_active.getAttribute('data-quantity')));
  const width_planned = Math.round(parseInt(progress_planned.getAttribute('data-quantity')));
  const width_active_planned = Math.round(parseInt(progress_active.getAttribute('data-quantity')) + parseInt(progress_planned.getAttribute('data-quantity')));

  // set the progress bar widths
  progress_active.style.width = width_active + '%';
  progress_active.style.opacity = 1;
  progress_planned.style.width = width_planned + '%';
  progress_planned.style.opacity = 1;

  /*
  // set the more-details widths
  setTimeout(() => {
    progress_active_details_left.style.width = width_active + '%';
    progress_active_details_right.style.width = (100 - width_active) + '%';

    if (width_active < 39) {
      progress_active_details_left.querySelector('.inner').style.display = 'none';
      progress_active_details_right.querySelector('.inner').style.display = 'block';
    } else {
      progress_active_details_left.querySelector('.inner').style.display = 'block';
      progress_active_details_right.querySelector('.inner').style.display = 'none';
    }

    progress_planned_details_left.style.width = width_active_planned + '%';
    progress_planned_details_right.style.width = (100 - width_active_planned) + '%';

    if (width_active_planned > 69) {
      progress_planned_details_left.querySelector('.inner').style.display = 'block';
      progress_planned_details_right.querySelector('.inner').style.display = 'none';
    } else {
      progress_planned_details_left.querySelector('.inner').style.display = 'none';
      progress_planned_details_right.querySelector('.inner').style.display = 'block';
    }
  }, 1.3*1000);
  */

};
