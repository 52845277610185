export const Navigato = () => {

	// create instance
	const navigato = {
		init: function() {

			// define elements
			const triggers = document.getElementsByClassName('trigger--navigato-perform');

			// bind events
			for(var i=0; i<triggers.length; i++) { triggers[i].addEventListener('click', function(e) { e.preventDefault(); navigato.perform(); }); }

		},
		perform: function() {

			// define variables
			var thisScrollPos = document.documentElement.scrollTop;
			var newScrollPos = thisScrollPos + 500;

			// perform scroll
			window.scrollTo({
				left: 0,
				top: newScrollPos,
				behavior: 'smooth'
			});

		}
	};

	// initialize instance
	navigato.init();

};
