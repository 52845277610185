
// This component can be modified to support async pages

class Pager {

    constructor(){
        this.code =  '';
        this.size;
        this.page;
        this.step;

        // --------------------
        // Utility
        // --------------------

        // converting initialize data
        this.Extend= (data) => {
            data = data || {};
            this.size = data.size || 300;
            this.page = data.page || 1;
            this.step = data.step || 3;
        }

        // add pages by number (from [s] to [f])
        this.Add = (s, f) => {
            for (var i = s; i < f; i++) {
                this.code += '<a>' + i + '</a>';
            }
        },

        // add last page with separator
        this.Last=()=> {
            this.code += '<i>...</i><a>' + this.size + '</a>';
        },

        // add first page with separator
        this.First = () => {
            this.code += '<a>1</a><i>...</i>';
        },



        // --------------------
        // Handlers
        // --------------------

        // change page
        this.Click = () => {
            this.page = +this.innerHTML;
            this.Start();
        },

        // previous page
        this.Prev = () => {
            this.page--;
            if (this.page < 1) {
                this.page = 1;
            }
            this.Start();
        },

        // next page
        this.Next=()=> {
            this.page++;
            if (this.page > this.size) {
                this.page = this.size;
            }
            this.Start();
        },



        // --------------------
        // Script
        // --------------------

        // binding pages
        this.Bind=()=> {
            var a = this.e.getElementsByTagName('a');
            for (var i = 0; i < a.length; i++) {
                if (+a[i].innerHTML === this.page) a[i].className = 'current';
                //a[i].addEventListener('click', Pager.Click, false);
                a[i].setAttribute("href", `${location.protocol}//${location.host}${location.pathname}?page=${a[i].innerHTML}`);
            }
        },

        // write Pager
        this.Finish=()=> {
            this.e.innerHTML = this.code;
            this.code = '';
            this.Bind();
        },

        // find Pager type
        this.Start =() => {
            if (this.size < this.step * 2 + 6) {
                this.Add(1, this.size + 1);
            }
            else if (this.page < this.step * 2 + 1) {
                this.Add(1, this.step * 2 + 4);
                this.Last();
            }
            else if (this.page > this.size - this.step * 2) {
                this.First();
                this.Add(this.size - this.step * 2 - 2, this.size + 1);
            }
            else {
                this.First();
                this.Add(this.page - this.step, this.page + this.step + 1);
                this.Last();
            }
            this.Finish();
        },



        // --------------------
        // Initialization
        // --------------------

        // binding buttons
        this.Buttons = (e) => {
            // var nav = e.getElementsByTagName('a');
            // nav[0].addEventListener('click', this.Prev, false);
            // nav[1].addEventListener('click', this.Next, false);
        },

        // create skeleton
        this.Create = (e) => {

            const _elements = document.getElementById('pagination').children;
            const _first = _elements[0].getAttribute("href");
            const _last = _elements[_elements.length - 1].getAttribute("href");

            var html = [
                `<a href="${_first}" class="pagination__arrow pagination__arrow__prev"></a>`, // previous button
                '<span class="pagination__rack"></span>',  // Pager container
                `<a href="${_last}" class="pagination__arrow pagination__arrow__next"></a>`  // next button
            ];

            e.innerHTML = html.join('');
            this.e = e.getElementsByTagName('span')[0];
            this.Buttons(e);
        };

        // init
        this.Init = (e, data) => {
            this.Extend(data);
            this.Create(e);
            this.Start();
        }
    }

    
}

let getQueryString = (k) => {
    k = k.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + k + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export  const Pagination = ()=>{

    const _element = document.getElementById('pagination') || false;
    if(!_element) return false;
    

    const pager = new Pager();

    pager.Init(_element, {
        size: parseInt(_element.getAttribute('data-length')), // pages size
        page: parseInt(getQueryString("page")) || 1,  // selected page
        step: 1   // pages before and after current
    });
}